export const PARTY_LIST_LOADED = "PARTY_LIST_LOADED";
export const POST_PARTY_DATA = "POST_PARTY_DATA";
export const GET_PARTY_DETAILS = "GET_PARTY_DETAILS";
export const PUT_PARTY_DETAILS = "PUT_PARTY_DETAILS";
export const DELETE_PARTY_DETAILS = "DELETE_PARTY_DETAILS";
export const GET_PARTY_LOGO = "GET_PARTY_LOGO";




