export const GET_NOMINATIONS = "GET_NOMINATIONS";
export const POST_NOMINATION_PAYMENTS = "POST_NOMINATION_PAYMENTS";
export const PUT_NOMINATION_PAYMENTS = "PUT_NOMINATION_PAYMENTS";
export const GET_NOMINATION_PAYMENTS = "GET_NOMINATION_PAYMENTS";
export const NOMINATIONS_LOADED = "NOMINATIONS_LOADED";
export const ON_NOMINATION_APPROVAL_CHANGE = "ON_NOMINATION_APPROVAL_CHANGE";
export const HANDLE_CHANGE_PAYMENT = "HANDLE_CHANGE_PAYMENT";
export const GET_NOMINATION_CANDIDATES = "GET_NOMINATION_CANDIDATES";
export const DELETE_NOMINATION_CANDIDATE = "DELETE_NOMINATION_CANDIDATE";
export const POST_NOMINATION_SUPPORT_DOC = "POST_NOMINATION_SUPPORT_DOC";
export const APPROVED_ELECTIONS = "APPROVED_ELECTIONS";
export const PARTY_LIST_LOADED = "PARTY_LIST_LOADED";
export const GET_NOMINATION_LIST = "GET_NOMINATION_LIST";
export const RECEIVE_NOMINATION_STATUS = "RECEIVE_NOMINATION_STATUS";
export const POST_CANDIDATE_SUPPORT_DOC = "POST_CANDIDATE_SUPPORT_DOC";
export const CANDIDATE_SUPPORT_DOC_LOADED = "CANDIDATE_SUPPORT_DOC_LOADED";
export const NOMINATION_PAYMENT_STATUS_LOADED = "NOMINATION_PAYMENT_STATUS_LOADED";
export const PAYMENT_LIST_LOADED = "PAYMENT_LIST_LOADED";
export const PAYMENT_SERIAL_NO_LOADED = "PAYMENT_SERIAL_NO_LOADED";
export const GET_NOMINATION_LIST_FOR_PAYMENT = "GET_NOMINATION_LIST_FOR_PAYMENT";
export const GET_NOMINATION_DATA = "GET_NOMINATION_DATA";
export const NOMINATION_PAYMENT_VALIDATION_LOADED = "NOMINATION_PAYMENT_VALIDATION_LOADED";
export const ORIGINAL_UPLOAD_PATH_LOADED = "ORIGINAL_UPLOAD_PATH_LOADED";
export const PARTY_LIST_BY_TEAM_TYPE_LOADED = "PARTY_LIST_BY_TEAM_TYPE_LOADED";
export const PENDING_NOMINATIONS_LOADED = "PENDING_NOMINATIONS_LOADED";





export const APPROVAL_STATE = {
  APPROVED: '1ST-APPROVE',
  REJECTED: 'REJECT',
  PENDING: 'pending',
};
export const AUTH_FAILED = "AUTH_FAILED";
export const ERROR = "ERROR";




