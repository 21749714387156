export const ELECTIONS_LOADING = 'ELECTIONS_LOADING';
export const ELECTION_LOAD_SUCCESS = 'ELECTION_LOAD_SUCCESS';
export const POST_ELECTION = 'POST_ELECTION';
export const GET_ELECTION_MODULE = 'GET_ELECTION_MODULE';
export const POST_CALL_ELECTION = 'POST_CALL_ELECTION';
export const POST_CALL_ELECTION_DATA = 'POST_CALL_ELECTION_DATA';
export const SET_CALL_ELECTION_DATA = 'SET_CALL_ELECTION_DATA';
export const ELECTION_REVIEW_DATA = 'ELECTION_REVIEW_DATA';
export const ON_ELECTION_APPROVAL_CHANGE = 'ON_ELECTION_APPROVAL_CHANGE';
export const SNACK_BAR_MESSAGE_LOADED = 'SNACK_BAR_MESSAGE_LOADED';
export const GET_ALL_ELECTIONS = 'GET_ALL_ELECTIONS';
export const RECEIVE_PENDING_ELECTION = 'RECEIVE_PENDING_ELECTION';
export const RECEIVE_APPROVED_ELECTION = 'RECEIVE_APPROVED_ELECTION';
export const GET_CALL_ELECTION_DATA = 'GET_CALL_ELECTION_DATA';
export const HANDLE_CHANGE_CALL_ELECTION = 'HANDLE_CHANGE_CALL_ELECTION';
export const EDIT_CALL_ELECTION_DATA = 'EDIT_CALL_ELECTION_DATA';
export const DELETE_CALL_ELECTION_DATA = 'DELETE_CALL_ELECTION_DATA';
export const GET_CALL_ELECTION_TIME_LINE_DATA = 'GET_CALL_ELECTION_TIME_LINE_DATA';
export const GET_ACTIVE_ELECTIONS = 'GET_ACTIVE_ELECTIONS';
export const SET_ELECTORATES_DIVISIONS = 'SET_ELECTORATES_DIVISIONS';
export const ELECTION_ELECTORATES_REVIEW_DATA = 'ELECTION_ELECTORATES_REVIEW_DATA';
export const ELECTION_ELIGIBILITY_REVIEW_DATA = 'ELECTION_ELIGIBILITY_REVIEW_DATA';
export const GET_PENDING_ELECTIONS_DATA = 'GET_PENDING_ELECTIONS_DATA';
export const GET_ELECTION_MODULE_CALL_ELECTION = 'GET_ELECTION_MODULE_CALL_ELECTION';







export const APPROVAL_STATE = {
    APPROVE: 'APPROVE',
    REJECT: 'REJECT',
    PENDING: 'PENDING',
  };










